import { message, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import ImagePopupModal from "../../NpComponents/ImagePopupModal/ImagePopupModal";
import InputField from "../../NpComponents/InputField/InputField";
import { fetchApartments } from "../../utils/api-calls/apartmentsCalls";
import { fetchReviews } from "../../utils/api-calls/landingCalls";

const AdminApartmentsEdit = (props) => {
  const { url } = props;
  const { authUser, setApartments } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [imagesItems, setImagesItems] = useState([])
  const [facilitiesItems, setFacilitiesItems] = useState([])

  const [dataValues, setDataValues] = useState({
    title: "",
    address: "",
    rating: "",
    tag: "",
    shortPricing: "",
    mediumPricing: "",
    longPricing: "",
    images: "",
    facilities: ""
  });

  useEffect(() => {
    setDataValues({
      images: props.updateData ? JSON.parse(props.updateData.images) : "",
      title: props.updateData ? props.updateData.title : "",
      address: props.updateData ? props.updateData.address : "",
      rating: props.updateData ? props.updateData.rating : "",
      tag: props.updateData ? props.updateData.tag : "",
      shortPricing: props.updateData ? props.updateData.shortPricing : "",
      mediumPricing: props.updateData ? props.updateData.mediumPricing : "",
      longPricing: props.updateData ? props.updateData.longPricing : "",
      facilities: props.updateData ? JSON.parse(props.updateData.facilities) : "",


    });
    if(props.updateData){
      const temp_facilities = JSON.parse(props.updateData.facilities)
      let temp_facility_item  = temp_facilities.map((tf)=> ({facility: tf}))
      setFacilitiesItems(temp_facility_item)


      const temp_images = JSON.parse(props.updateData.images)
      let temp_image_item  = temp_images.map((ti)=> ({image: ti, visible: false}))
      setImagesItems(temp_image_item)
    }
  }, [props.updateData]);
  useEffect(()=>{})
  const addData = async () => {
    if (imagesItems.length<0 || facilitiesItems.length<0
      || !dataValues.longPricing
      || !dataValues.mediumPricing
      || !dataValues.shortPricing
      || !dataValues.tag
      || !dataValues.rating
      || !dataValues.address
      || !dataValues.title

    ) {
      return message.error("Please insert required values!");
    }
    const temp_facilities = facilitiesItems.map(fd=> fd.facility)
    dataValues.facilities = JSON.stringify(temp_facilities)

    const temp_images = imagesItems.map(it=> it.image)
    dataValues.images = JSON.stringify(temp_images)
    try {
      const res = await fetch(url, {
        method: props.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          props.updateData
            ? {
              ...dataValues,
              id: props.updateData.id,
            }
            : {
              ...dataValues,
            }
        ),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let apartmentsf = await fetchApartments();
        setApartments(apartmentsf);
        return message.success(data.message);
      }
      console.log(data.error);
      return message.error(data.message);
    } catch (error) {
      message.error(`${props.updateData ? "Updating" : "Adding"} Failed!`);
    }
  };
  console.log("facilities items", facilitiesItems)
  return (
    <div>
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="title"
        type="text"
        placeholder="Enter the title..."
        label="Title"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="address"
        type="text"
        placeholder="Enter the address..."
        label="Address"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="rating"
        type="text"
        placeholder="Enter the rating..."
        label="Ratings"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="tag"
        type="text"
        placeholder="Enter the tag..."
        label="Tagline"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="shortPricing"
        type="text"
        placeholder="Enter the day/s pricing..."
        label="Day/s"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="mediumPricing"
        type="text"
        placeholder="Enter the week/s pricing..."
        label="Week/s"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="longPricing"
        type="text"
        placeholder="Enter the month pricing..."
        label="Month Pricing"
      />
      <div className="repeater-container">
        <div className="repeater-top">
          <div className="repeater-title">Images</div>
          <div className="repeater-button" onClick={() => {
            setImagesItems(prev => [...prev, { image: "", visible: false }])
          }}>
            <span>
              <i class="fa-solid fa-plus"></i>
            </span>
          </div>
        </div>
        <div className="repeater-children">
          {
            imagesItems.map((it, index) => {
              return <div className="repeater-inner">
                <ImagePopupModal
                  visible={imagesItems[index].visible}
                  setVisible={(state) => {
                    setImagesItems(prev => {
                      return prev.map((pr, index1) => {
                        if (index === index1) {
                          pr.visible = state
                        }
                        return pr
                      })
                    })
                  }}
                  myImage={it.image}
                  setMyImage={setImagesItems}
                  name="image"
                  dataValues={imagesItems}
                  count={index + 1}
                  heading={`Select Image ${index + 1}`}
                />
                <div className="repeater-delete" onClick={() => {
                  let mm = imagesItems;
                  mm.splice(index, 1)
                  setImagesItems([...mm])
                }}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            })
          }

        </div>
      </div>


      <div className="repeater-container">
        <div className="repeater-top">
          <div className="repeater-title">Facilities</div>
          <div className="repeater-button" onClick={() => {
            setFacilitiesItems(prev => [...prev, { facility: "" }])
          }}>
            <span>
              <i class="fa-solid fa-plus"></i>
            </span>
          </div>
        </div>
        <div className="repeater-children">
          {
            facilitiesItems.map((it, index) => {
              return <div className="repeater-inner">
                <InputField
                  inputValue={it.facility}
                  setInputValue={setFacilitiesItems}
                  name="facility"
                  type="text"
                  placeholder="Enter the facility..."
                  label={`Facility ${index + 1}`}
                  count={index + 1}
                />
                <div className="repeater-delete" onClick={() => {
                  let mm = facilitiesItems;
                  mm.splice(index, 1)
                  setFacilitiesItems([...mm])
                }}>
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
            })
          }

        </div>
      </div>

      <button onClick={() => addData()} className="np-admin-main-button">
        {props.updateData ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default AdminApartmentsEdit;
