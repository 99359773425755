import React from 'react'
import { useContext } from 'react'
import { amenitiesData } from '../../Assets/Data/Amenities'
import { distancesData } from '../../Assets/Data/Distances'
import ContactInfo from '../../Components/ContactInfo/ContactInfo'
import { AuthContext } from '../../Context/AuthContext/AuthContext'
import { server } from '../../utils/fetch'
import './styles.scss'
const About = () => {
  const { about, distances, amenities } = useContext(AuthContext)

  return (
    <div className='about-page'>
      <div className="container">
        <div className="about-page-inner">
          <div className="about-page-texts">
            <h2 className="section-heading">{about && about.heading}</h2>
            <h3 className="section-sub-heading">{about && about.subHeading}</h3>
            <p className="about-details">
              {about && about.detail}
            </p>
          </div>
          <div className="about-page-image">
          <img loading="lazy" src={`${server}/${about && about.image}`} alt="" />
          </div>
        </div>
        <div className="about-medium">
          <div className="about-amenities">
            <h3 className="section-sub-heading">All amenities available inside the apartment</h3>
            <div className="amenities-list-container">
              {amenities.map((am, index) => {
                return <div className="amenities-list-item" key={index}>
                  <span><i class={am.icon}></i></span>
                  {am.name}
                </div>
              })}
            </div>
          </div> 
          <div className="about-distances">
            <h3 className="section-sub-heading">Distances from rupas home</h3>
            <div className="distances-list-container">
              {
                distances.map((dd, index) => {
                  return <div className="distance-list-item" key={index}>
                    <span className="distance-text">{dd.location}</span>
                    <span className="distance-line"></span>
                    <span className="distance-d">{dd.distance} km</span>
                  </div>
                })
              }
            </div>
          </div>


        </div>
      </div>

      <ContactInfo />
      <div className="about-bottom">

        <div className="about-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3533.1620162607524!2d85.3334443150615!3d27.681386982802774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDQwJzUzLjAiTiA4NcKwMjAnMDguMyJF!5e0!3m2!1sen!2snp!4v1678867843082!5m2!1sen!2snp"
            width="100%" height="440"
            allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>



    </div>
  )
}

export default About