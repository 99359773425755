import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { Image, message, Modal } from "antd";
import InputField from "../../NpComponents/InputField/InputField";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import { fetchFooterAbout } from "../../utils/api-calls/footerCalls";
const FooterAboutDetail = () => {
  let url = `${server}/footerAbout`;
  const { authUser, footerAbout, setFooterAbout } =
    useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [dataValues, setDataValues] = useState({
    about: "",
  });

  useEffect(() => {
    if (footerAbout) {
      setDataValues({
        about: footerAbout.about,
      });
    }
  }, [footerAbout]);
  console.log("footer about", footerAbout);

  const updateDatabase = async () => {
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataValues),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let footerAboutf = await fetchFooterAbout();
        setFooterAbout(footerAboutf);
        return message.success(data.message);
      }

      return message.error(data.message);
    } catch (error) {
      message.error(`Updating" Failed!`);
    }
  };
  return (
    <div className="admin-landing-hero">
      <div className="main-banner">
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="about"
          type="text"
          placeholder="Enter Reach Us Info..."
          label="Reach Us Info"
          textArea
        />

        <button
          className="np-admin-main-button"
          onClick={() => {
            updateDatabase();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default FooterAboutDetail;
