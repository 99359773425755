import React from 'react'
import { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
// import { accordionData } from '../../Assets/Data/Accordion';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
import { server } from '../../utils/fetch';
import './styles.scss';
const LandingAccordion = () => {
    const {faq, faqInfo} = useContext(AuthContext)
    return (
        <section className='landing-accordion-section' style={{backgroundImage:`url(${server}/${faqInfo && faqInfo.image})`}}>
            <div className="container">
                <h2 className="section-heading">{faqInfo && faqInfo.heading}</h2>
                <h3 className="section-sub-heading">{faqInfo && faqInfo.subHeading}</h3>
                <div className="landing-accordion-inner">
                    <Accordion defaultActiveKey="0">
                        {
                            faq.map((acc, index) => {
                                return <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{acc.title}</Accordion.Header>
                                    <Accordion.Body>
                                        {acc.detail}
                                    </Accordion.Body>
                                </Accordion.Item>
                            })
                        }
                    </Accordion>

                </div>
            </div>
        </section>
    )
}

export default LandingAccordion