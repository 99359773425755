import React from 'react'
import './styles.scss'
import NotFoundImage from '../../Assets/Images/Layout/error.png'
const NotFoundPage = () => {
  return (
    <div className='not-found-container'>
        <div className="container">
            <div className="not-found-inner">
                <img src={NotFoundImage} alt="" />
            </div>
        </div>
    </div>
  )
}

export default NotFoundPage