import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import { useContext } from 'react'
import { AuthContext } from '../../Context/AuthContext/AuthContext'
import Logo from '../../Assets/Images/Brand/logowhite.png'

const Footer = () => {
  const {footerAbout,footerBooking, footerLinks, footerContact} = useContext(AuthContext)
  return (
    <footer className='footer-section'>
      <div className="container">
        <div className="footer-inner">
          <div className="footer-about">
            <Link>
             {/* Rupas Home */}
              <img loading="lazy" src={Logo} alt="" />
            </Link>
            <p className="footer-text">
{footerAbout && footerAbout.about}
            </p>
          </div>
          {/* <div className="footer-visit">
            <h3 className="footer-heading">
              Visit Booking.Com
            </h3>
            <p className="footer-text">
             {footerBooking && footerBooking.info}
            </p>
            <a href={footerBooking && footerBooking.link} className="alternate-button">Visit</a>
          </div> */}
          <div className="footer-links-container">
            <h3 className="footer-heading">Useful Links</h3>
            <div className="footer-links">
              <Link to="/" className="footer-link">Home</Link>
              <Link to="/apartments" className="footer-link">Appartments</Link>
              <Link to="/about-us" className="footer-link">About Us</Link>
              <Link to="/contact-us" className="footer-link">Contact Us</Link>
            </div>
          </div>
          <div className="footer-contact">
            <h3 className="footer-heading">Contact Details</h3>
            <div className="footer-contact-list">
              <div className="footer-contact-item">
                <span><i class="fa-solid fa-envelope"></i></span> {footerContact && footerContact.email}
              </div>
              <div className="footer-contact-item">
                <span><i class="fa-solid fa-phone"></i></span> {footerContact && footerContact.phone}
              </div>
              <div className="footer-contact-item">
                <span><i class="fa-solid fa-location-dot"></i></span> {footerContact && footerContact.address}
              </div>

            </div>
            <div className="social-media-container">
              <a href={footerLinks && footerLinks.facebook} className="social-icon">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              {/* <a href={footerLinks && footerLinks.twitter} className="social-icon">
                <i class="fa-brands fa-twitter"></i>
              </a> */}
              <a href={footerLinks && footerLinks.instagram} className="social-icon">
                <i class="fa-brands fa-instagram"></i>
              </a>
              {/* <a href="https://youtube.com" className="social-icon">
                <i class="fa-brands fa-youtube"></i>
              </a> */}
              <a href={`https://wa.me/${footerLinks && footerLinks.whatsapp}`} className='social-icon'>
                <i class="fa-brands fa-whatsapp"></i>
                </a>
                <a href={`viber://chat?number=${footerLinks && footerLinks.viber}`} className='social-icon'>
                <i class="fa-brands fa-viber"></i>
                </a>
                <a href={`tel:${footerLinks && footerLinks.phone}`} className='social-icon'>
                <i class="fa-solid fa-phone"></i>                
                </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-left">© Rupas Home 2023 | created and maintained by <a href="https://syronx.com">SyronX Technology</a></div>
        <div className="footer-right"></div>
      </div>
    </footer>
  )
}

export default Footer