import { message, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { amenitiesData } from "../../Assets/Data/Amenities";
import { amenitiesClassData } from "../../Assets/Data/AmenitiesClass";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import ImagePopupModal from "../../NpComponents/ImagePopupModal/ImagePopupModal";
import InputField from "../../NpComponents/InputField/InputField";
import { fetchAmenities, fetchDistances } from "../../utils/api-calls/aboutCalls";
import { fetchFaqs, fetchReviews } from "../../utils/api-calls/landingCalls";

const AdminAmenitiesEdit = (props) => {
  const { url } = props;
  const { authUser, setAmenities, amenities } = useContext(AuthContext);

  const [dataValues, setDataValues] = useState({
    name: "",
    icon: ""
  });
  const [amenitiesIcon, setAmenitiesIcon] = useState(null)

  useEffect(() => {
    setDataValues({
      name: props.updateData ? props.updateData.name : "",
      icon: props.updateData ? props.updateData.icon : "",

    });
    if(props.updateData){
      let Tep_pada = (props.updateData.icon)
      setAmenitiesIcon(Tep_pada)
    }
  }, [props.updateData]);
  const addData = async () => {
    if (!dataValues.name || !amenitiesIcon) {
      return message.error("Please insert required values!");
    }
    dataValues.icon = amenitiesIcon
    try {
      const res = await fetch(url, {
        method: props.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          props.updateData
            ? {
              ...dataValues,
              id: props.updateData.id,
            }
            : {
              ...dataValues,
            }
        ),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let distancesf = await fetchAmenities();
        setAmenities(distancesf);
        return message.success(data.message);
      }
      console.log(data.error);
      return message.error(data.message);
    } catch (error) {
      message.error(`${props.updateData ? "Updating" : "Adding"} Failed!`);
    }
  };
  console.log("amenities icon", amenitiesIcon, dataValues)
  return (
    <div>
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="name"
        type="text"
        placeholder="Enter the name..."
        label="Amenities Name"
      />
      {/* <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="icon"
        type="text"
        placeholder="Enter the icon..."
        label="Amenities Icon"
      /> */}
      <div className="amenities-icon-container">
        <div className="amenities-title">Select Amenities Icon (only one)</div>
        <div className="amenities-icon-inner">
          {amenitiesClassData.map((ad, index) => {
            return <div className={`amenities-icon ${amenitiesIcon == ad.icon ? 'amenities-icon-selected' : ''}`} onClick={() => {
              if (amenitiesIcon == ad.icon) {
                setAmenitiesIcon(null)
              }
              else {
                setAmenitiesIcon(ad.icon)
              }
            }}>
              <i class={ad.icon}></i>
            </div>
          })}

        </div>

      </div>

      <button onClick={() => addData()} className="np-admin-main-button">
        {props.updateData ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default AdminAmenitiesEdit;
