import { message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import { distancesData } from '../../Assets/Data/Distances'
import RatingComponent from '../../Components/RatingComponent/RatingComponent'
import { AuthContext } from '../../Context/AuthContext/AuthContext'
import { server } from '../../utils/fetch'
import Form from 'react-bootstrap/Form';
import BookImage from '../../Assets/Images/Layout/book.png'
import './styles.scss'
const ApartmentInner = () => {
  const { apartmentsName } = useParams()
  const { apartments, distances } = useContext(AuthContext)
  const [myApartment, setMyApartment] = useState(null)
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [imessage, setMessage] = useState("")
  const [viber, setViber] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (apartments && apartments.length > 0) {
      let cc = apartments.find((ap) => ap.title.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-").replaceAll("(", "").replaceAll(")", "") == apartmentsName)
      setMyApartment(cc)
    }
  }, [apartments, apartments.length])
  useEffect(() => {
    var bookBox = document.querySelector(".book-box");
    window.addEventListener("scroll", () => {
      let options = {
        // root: document.querySelector("#comment-box"),
        rootMargin: "0px",
        threshold: 0.7,
      };

      let callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (
            window.scrollY > 250 &&
            !entry.isIntersecting &&
            entry.boundingClientRect.y > 0
          ) {
            bookBox.classList.add("book-box-sticky");
          } else {
            bookBox.classList.remove("book-box-sticky");
          }
        });
      };
      let observer = new IntersectionObserver(callback, options);
      let target = document.querySelector("#apartment-map");
      observer.observe(target);
    });

  }, [])




  const addData = async (e) => {
    e.preventDefault();
    console.log("form submit", e)
    if (!name || !viber || !whatsapp || !phone || !imessage || !address) {
      return message.error("Please insert all the values!");
    }
    try {
      const res = await fetch(`${server}/reservation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          viber,
          whatsapp,
          address,
          message: imessage,
          apartment: myApartment.id,
          title: myApartment.title
        }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        setName("");
        setEmail("");
        setMessage("");
        setAddress("");
        setPhone("");
        setViber("");
        setWhatsapp("")
        setVisible(false)
        return message.success(data.message);
      }
      console.log("Error", data)
      return message.error(data.message);

    } catch (error) {
      message.error(`Reservation request failed!`);
      console.log("Error", error)
    }
  };
  const settings = {
    customPaging: function (i) {
      return (
        <a className='slick-dot-anchor'>
          <img loading="lazy" className='slick-dot-image' src={`${server}/${myApartment && JSON.parse(myApartment.images) && JSON.parse(myApartment.images)[i]}`} />
          <div className="slick-dot-shade"></div>
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className='apartment-inner'>
      <div className="apartment-heading-section">
        <div className="container">
          <div className="apartment-heading-inner">
            <div className="apartment-heading-top">
              <h2 className="apartment-title">{myApartment && myApartment.title}</h2>
              <div className="apartment-sub-title">
                <div className="apartment-address"><span className="apartment-address-icon"><i class="fa-solid fa-location-dot"></i></span>Shankamul, Baneshwor</div>
                <div className="apartment-rating">

                  {myApartment && <RatingComponent rating={Number(myApartment.rating)} />}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="apartment-images-section">
        <div className="container">
          <div className="apartment-images-inner">
            <div className="ap-images-top">
              <div className="ap-images-slide">
                {myApartment && <Slider {...settings} className="ap-slider">
                  {
                    myApartment
                    && JSON.parse(myApartment.images)
                    && JSON.parse(myApartment.images).map((mg, index) => {
                      return <div className="ap-images-con">
                        <img loading="lazy" src={`${server}/${mg}`} alt="" />
                      </div>
                    })}
                </Slider>}
              </div>
              {/* {myApartment && <img loading="lazy" src={myApartment.image} alt="" />} */}
              <div className="book-box">
                <div className="ap-type">{myApartment && myApartment.tag}</div>
                <div className="ap-price">Pricing</div>
                <div className="ap-price-list">
                  <span className="price-heading">Day/s</span>
                  <span className="price-text">{myApartment && myApartment.shortPricing}</span>
                </div>
                <div className="ap-price-list">
                  <span className="price-heading">Week/s</span>
                  <span className="price-text">{myApartment && myApartment.mediumPricing}</span>
                </div>
                <div className="ap-price-list">
                  <span className="price-heading">Month</span>
                  <span className="price-text">{myApartment && myApartment.longPricing}</span>
                </div>
                <Modal
                  centered
                  visible={visible}
                  footer={false}
                  destroyOnClose
                  onOk={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  width={1000}
                  bodyStyle={{ padding: "40px" }}
                  className='reservation-modal'
                >
                  <div className="reservation-modal-container">
                    <div className="reservation-modal-image">
                      <img loading="lazy" src={'https://img.freepik.com/premium-vector/hotel-booking-online-hand-holds-smartphone-hotel-reservation-online-booking-concept-flat-style_198838-128.jpg?w=2000'} alt="" />
                    </div>
                    <div className="section-sub-heading reservation-modal-title">Reservation</div>
                    <Form onSubmit={(e) => addData(e)}>
                      <Form.Group className="mb-2" controlId="formBasicName" >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} />
                      </Form.Group>
                      <Form.Group className="mb-2" controlId="formBasicAddress" >
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                      </Form.Group>
                      <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Label>Email address (optional)</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-2" controlId="formBasicPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="number" placeholder="Enter Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <Form.Text className="text-muted">
                          We'll never share your phone with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-2" controlId="formBasicViber">
                        <Form.Label>Viber</Form.Label>
                        <Form.Control type="number" placeholder="Enter Viber" value={viber} onChange={(e) => setViber(e.target.value)} />
                        <Form.Text className="text-muted">
                          We'll never share your viber with anyone else.
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-2" controlId="formBasicWhatsApp">
                        <Form.Label>Whats App</Form.Label>
                        <Form.Control type="number" placeholder="Enter Whatsapp" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} />
                        <Form.Text className="text-muted">
                          We'll never share your whatsapp with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-2" controlId="formBasicMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control type="text" placeholder="Enter message" as="textarea" aria-label="With textarea" value={imessage} onChange={(e) => setMessage(e.target.value)} />

                      </Form.Group>

                      <button className='main-button' type="submit">
                        Submit
                      </button>
                    </Form>
                  </div>
                </Modal>
                {myApartment && <button className="main-button" onClick={() => setVisible(true)}>Reserve</button>
                }</div>
            </div>
            {/* <div className="facilities-collection">
              {
                facilitiesData.map((fd, index) => {
                  return <FacilitiesItem item={fd} key={index} />
                })
              }
            </div> */}
          </div>

        </div>
      </div>
      <div className="apartment-list">
        <div className="container">
          <h2 className="section-sub-header">Facilities</h2>
          <div className="apartment-list-inner">
            {
              myApartment &&
              JSON.parse(myApartment.facilities) &&
              JSON.parse(myApartment.facilities).map((fc, index) => {

                return <div className="ap-list-item" key={index}>
                  <span className='ap-item-icon'><i class="fa-solid fa-circle"></i></span>
                  <span className="ap-item-text">
                    {fc}
                  </span>
                </div>
              })
            }
          </div>
        </div>
      </div>
      <div className="ap-distances">
        <div className="container">

          <div className="about-distances">
            <h3 className="section-sub-heading">Distances from rupas home</h3>
            <div className="distances-list-container">
              {
                distances.map((dd, index) => {
                  return <div className="distance-list-item" key={index}>
                    <span className="distance-text">{dd.location}</span>
                    <span className="distance-line"></span>
                    <span className="distance-d">{dd.distance} km</span>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
      {/* <div className="apartment-desc">
        <div className="container">
          <div className="apartment-desc-inner">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Blanditiis inventore rerum cum non quos similique nulla perspiciatis
            nesciunt dolores reiciendis fugiat vel iusto repellat provident,
            assumenda recusandae ab molestiae vero. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Ea numquam, quod quis eius molestias excepturi voluptatum.
            Laboriosam doloribus quaerat eveniet ullam reprehenderit,
            odio velit quo, culpa aperiam perferendis officia consequuntur.
            <br /><br />
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Blanditiis inventore rerum cum non quos similique nulla perspiciatis
            nesciunt dolores reiciendis fugiat vel iusto repellat provident,
            assumenda recusandae ab molestiae vero. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Ea numquam, quod quis eius molestias excepturi voluptatum.
            Laboriosam doloribus quaerat eveniet ullam reprehenderit,
            odio velit quo, culpa aperiam perferendis officia consequuntur.

          </div>
        </div>
      </div> */}

      <div className="apartment-map" id='apartment-map'>
        <div className="container">
          <div className="apartment-map-inner">
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3533.1620162607524!2d85.3334443150615!3d27.681386982802774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDQwJzUzLjAiTiA4NcKwMjAnMDguMyJF!5e0!3m2!1sen!2snp!4v1678867843082!5m2!1sen!2snp"
              width="100%" height="500"
              allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ApartmentInner