import React from 'react'
import './styles.scss'
import Slider from "react-slick";
import AppartmentSlideItem from '../AppartmentSlideItem/AppartmentSlideItem';
import { appartmentData } from '../../Assets/Data/Appartments';
import { reviewsData } from '../../Assets/Data/Reviews';
import ReviewSlideItem from '../ReviewsSlideItem/ReviewsSlideItem';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
import { server } from '../../utils/fetch';

const LandingReviewsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  const {reviews, reviewInfo} = useContext(AuthContext)


  return (
    <section className='landing-reviews-section'>
      <div className="container">
        <div className="landing-reviews-inner">
          <div className="reviews-certified" data-aos="fade-right">
            <img loading="lazy" src={`${server}/${reviewInfo && reviewInfo.image}`} alt="" />
          </div>
          <div className="review-slider-outer" data-aos="fade-left">
            <div className="review-slider-inner">
            <h2 className="section-heading">{reviewInfo && reviewInfo.heading}</h2>
        <h3 className="section-sub-heading">{reviewInfo && reviewInfo.subHeading}</h3>

              <Slider className='reviews-slider' {...settings}>
                {reviews.map((ap, index) => {
                  return <ReviewSlideItem key={index} item={ap} />

                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingReviewsSection