export const amenitiesClassData = [
    {
        icon: "fa-solid fa-house",
        name: "Fully furnished Apartments",
    },
    {
        icon: "fa-solid fa-kitchen-set",
        name: "Fully equipped private kitchen in every apartments",
    },
    {
        icon: "fa-solid fa-fan",
        name: "AC",
    },
    {
        icon: "fa-solid fa-desktop",
        name: "TV",
    }, {
        icon: "fa-solid fa-dumpster",
        name: "Washing machine in every apartment",
    },
    {
        icon: "fa-solid fa-temperature-low",
        name: "Refrigerator",
    },
    {
        icon: "fa-solid fa-wifi",
        name: "Wifi",
    },
    {
        icon: "fa-solid fa-broom",
        name: "House keeping",
    },
    {
        icon: "fa-solid fa-shirt",
        name: " Laundry services",
    },
    {
        icon: "fa-solid fa-glass-water-droplet",
        name: "Free drinking water jar",
    },
    {
        icon: "fa-solid fa-square-parking",
        name: "Parking (reservation needed)",
    },
    {
        icon: "fa-solid fa-video",
        name: "CCTV 24 Hr monitoring",
    },
    {
        icon: "fa-solid fa-martini-glass-citrus",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-square-parking",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-people-roof",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-leaf",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-dumbbell",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-wheelchair",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-bicycle",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-truck-plane",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-shirt",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-crown",
        name:"Martini"
    },
    {
        icon: "fa-solid fa-bath",
        name:"Bath"
    },
    {
        icon: "fa-solid fa-toilet",
        name:"Toilet"
    },
]