import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext/AuthContext";
import "./styles.scss";
const RightCol = () => {
  const { contact } = useContext(AuthContext);
  return (
    <div className="right-col-container ">
      <div className="right-col-contact myScrollbar">
        <div className="right-col-contact-header">Recent Contacts</div>
        {contact.map((co, index) => {
          return (
            <div className="right-contact-list" key={index}>
              <div className="right-contact-name">{co.name}</div>
              <div className="right-contact-message">{co.message}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RightCol;
