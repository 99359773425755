import React from 'react'
import { Link } from 'react-router-dom'
import { server } from '../../utils/fetch'
import RatingComponent from '../RatingComponent/RatingComponent'
import './styles.scss'
const ApartmentPageItem = (props) => {
    const {item} = props
    console.log(item)
    return (
        <div className='apartment-page-item-container'>
            <div className="ap-image">
                <img loading="lazy" src={`${server}/${JSON.parse(item.images)[0]}`} alt="" />
            </div>
            <div className="ap-desc">
                <div className="ap-top">
                    <div className="ap-top-left">
                        <h3 className="ap-name">{item.title}</h3>
                        <p className="ap-location"><span><i class="fa-solid fa-location-dot"></i></span> Sankhamul, Kathmandu, Nepal</p>
                    </div>
                    <div className="ap-rating"><RatingComponent rating={Number(item.rating)} /></div>
                </div>
                <div className="ap-middle">
                  <span><i class="fa-solid fa-leaf"></i></span>  Eco Friendly
                </div>
                <div className="ap-bottom">
                    {/* <div className="ap-price">{item && item.mediumPricing} / </div> */}
                    <div className="ap-availability"><span style={{color:"green"}}><i class="fa-solid fa-circle"></i></span> Available</div>
                </div>
                <div className="ap-below">
                    <Link className="main-button" to={`/apartments-inner/${item.title.replaceAll(" ", "-").replaceAll("(",""). replaceAll(")","").replaceAll("/","-").toLowerCase()}`}>View Property</Link>
                </div>
            </div>
        </div>
    )
}

export default ApartmentPageItem