import { message, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import ImagePopupModal from "../../NpComponents/ImagePopupModal/ImagePopupModal";
import InputField from "../../NpComponents/InputField/InputField";
import { fetchReviews } from "../../utils/api-calls/landingCalls";

const AdminReviewsEdit = (props) => {
  const { url } = props;
  const { authUser, setReviews } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);

  const [dataValues, setDataValues] = useState({
    name: "",
    rating: "",
    image: "",
    detail: ""
  });

  useEffect(() => {
    setDataValues({
      image: props.updateData ? props.updateData.image : "",
      name: props.updateData ? props.updateData.name : "",
      rating: props.updateData ? props.updateData.rating : "",
      detail: props.updateData ? props.updateData.detail : "",

    });
  }, [props.updateData]);
  const addData = async () => {
    if (!dataValues.image) {
      return message.error("Please insert required values!");
    }
    try {
      const res = await fetch(url, {
        method: props.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          props.updateData
            ? {
              ...dataValues,
              id: props.updateData.id,
            }
            : {
              ...dataValues,
            }
        ),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let reviewsf = await fetchReviews();
        setReviews(reviewsf);
        return message.success(data.message);
      }
      console.log(data.error);
      return message.error(data.message);
    } catch (error) {
      message.error(`${props.updateData ? "Updating" : "Adding"} Failed!`);
    }
  };
  return (
    <div>
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="name"
        type="text"
        placeholder="Enter the name..."
        label="Name"
      />

      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="rating"
        type="text"
        placeholder="Enter the rating..."
        label="Ratings"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="detail"
        type="text"
        textArea
        placeholder="Enter the detail..."
        label="Detail"
      />
      <ImagePopupModal
        visible={visible}
        setVisible={setVisible}
        myImage={dataValues.image}
        setMyImage={setDataValues}
        name="image"
        dataValues={dataValues}
        heading="Select Image"
      />

      <button onClick={() => addData()} className="np-admin-main-button">
        {props.updateData ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default AdminReviewsEdit;
