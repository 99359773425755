import React, { useEffect, useState } from "react";
import { logout } from "../../utils/api-calls/authCalls";
import CryptoJS from "crypto-js";
export const AuthContext = React.createContext();

const AuthProvider = ({ children, ...props }) => {
  const [authUser, setAuthUser] = useState(null);
  const [authStatus, setAuthStatus] = useState("checking");

  const [heroTitle, setHeroTitle] = useState(null);
  const [landingHero, setLandingHero] = useState(null)
  const [about, setAbout] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [faq, setFaq] = useState([]);
  const [distances, setDistances] = useState([]);
  const [contactInfo, setContactInfo] = useState(null);
  const [contact, setContact] = useState([]);
  const [reservation, setReservation] = useState([]);
  const [apartments, setApartments] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [apartmentInfo, setApartmentInfo] = useState(null);
  const [faqInfo, setFaqInfo] = useState(null);
  const [reviewInfo, setReviewInfo] = useState(null);


  const [membershipImage, setMembershipImage] = useState(null);
  const [board, setBoard] = useState([]);
  const [galleryOuter, setGalleryOuter] = useState(null);
  const [galleryOuterMobile, setGalleryOuterMobile] = useState(null);

  const [footerLinks, setFooterLinks] = useState(null);
  const [footerAbout, setFooterAbout] = useState(null);
  const [footerContact, setFooterContact] = useState(null);
  const [footerBooking, setFooterBooking] = useState(null);

  const [refreshToken, setRefreshToken] = useState(null);
  const [acessToken, setAcessToken] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [sessionDuration, setSessionDuration] = useState([]);
  const [bounceRate, setBounceRate] = useState([]);
  const [sessionPerUser, setSessionPerUser] = useState([]);
  const [trafficSource, setTrafficSource] = useState([]);
  const [sessionsByDevice, setSessionsByDevice] = useState([]);
  const [countryBasedUser, setCountryBasedUser] = useState([]);
  const [pageViews, setPageViews] = useState([]);
  const [secret, setSecret] = useState("alohomora");

  const [membership, setMembership] = useState([]);
  const [brandingText, setBrandingText] = useState([]);
  const [menu, setMenu] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [mobileBanner, setMobileBanner] = useState([]);

  const [blog, setBlog] = useState([]);
  const setLogout = () => {
    logout();
    setAcessToken(null);
    setRefreshToken(null);
    setAuthUser(null);
  };
  const providerValue = {
    apartmentInfo,
    setApartmentInfo,
    faqInfo,
    setFaqInfo,
    reviewInfo,
    setReviewInfo,
    footerBooking,
    setFooterBooking,
    amenities,
    setAmenities,
    apartments,
    setApartments,
    reservation,
    setReservation,
    contactInfo,
    setContactInfo,
    distances,
    setDistances,
    faq,
    setFaq,
    reviews,
    setReviews,
    landingHero,
    setLandingHero,
    membershipImage,
    setMembershipImage,
    brandingText,
    setBrandingText,
    galleryOuter,
    setGalleryOuter,
    galleryOuterMobile,
    setGalleryOuterMobile,
    mobileBanner,
    setMobileBanner,
    blog,
    setBlog,
    gallery,
    setGallery,
    menu,
    setMenu,
    membership,
    setMembership,
    heroTitle,
    setHeroTitle,
    board,
    setBoard,
    authUser,
    setAuthUser,
    contact,
    setContact,
    footerLinks,
    setFooterLinks,
    footerAbout,
    setFooterAbout,
    footerContact,
    setFooterContact,
    about,
    setAbout,
    acessToken,
    setAcessToken,
    refreshToken,
    setRefreshToken,
    activeUsers,
    setActiveUsers,
    sessionDuration,
    setSessionDuration,
    bounceRate,
    setBounceRate,
    sessionPerUser,
    setSessionPerUser,
    trafficSource,
    setTrafficSource,
    sessionsByDevice,
    setSessionsByDevice,
    countryBasedUser,
    setCountryBasedUser,
    pageViews,
    setPageViews,
    setLogout,
    secret,
    setSecret,
    authStatus,
    setAuthStatus,
  };
  useEffect(() => {
    let data = localStorage.getItem("rupasAuth");
    if (
      data &&
      CryptoJS.AES.decrypt(data, secret) &&
      CryptoJS.AES.decrypt(data, secret).toString(CryptoJS.enc.Utf8)
    ) {
      const decryptUser = CryptoJS.AES.decrypt(data, secret);
      const originalText = decryptUser.toString(CryptoJS.enc.Utf8);
      setAuthUser(JSON.parse(originalText));
      setAuthStatus("checked");
    } else {
      setAuthStatus("checked");

      setLogout();
    }
  }, []);
  useEffect(() => {
    const newToken = localStorage.getItem("rupasToken");
    if (
      newToken &&
      CryptoJS.AES.decrypt(newToken, secret) &&
      CryptoJS.AES.decrypt(newToken, secret).toString(CryptoJS.enc.Utf8)
    ) {
      const decryptAccess = CryptoJS.AES.decrypt(newToken, secret);
      const originalText = decryptAccess.toString(CryptoJS.enc.Utf8);
      setAcessToken(JSON.parse(originalText));
    } else {
      setLogout();
    }
  }, []);
  useEffect(() => {
    const refreshToken = localStorage.getItem("rupasRefreshToken");
    if (
      refreshToken &&
      CryptoJS.AES.decrypt(refreshToken, secret) &&
      CryptoJS.AES.decrypt(refreshToken, secret).toString(CryptoJS.enc.Utf8)
    ) {
      const decryptRefresh = CryptoJS.AES.decrypt(refreshToken, secret);
      const originalText = decryptRefresh.toString(CryptoJS.enc.Utf8);
      setRefreshToken(JSON.parse(originalText));
    } else {
      setLogout();
    }
  }, []);
  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
