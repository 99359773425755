import React from "react";
import "./styles.scss";
const InputField = (props) => {
  const {
    inputValue,
    setInputValue,
    name,
    textArea,
    label,
    type,
    placeholder,
    count,
    readonly
  } = props;
  return (
    <div className="admin-input-field">
      <div className="input-label">{label}</div>
      {textArea ? (
        <textarea
          rows={10}
          value={count? inputValue:inputValue[name]}
          onChange={(e) => {
            setInputValue((prev)=>({...prev, [name]: e.target.value }));
          }}
          type={type}
          placeholder={placeholder}
          readOnly={readonly? true : false}
        />
      ) : (
        <input
        value={count? inputValue:inputValue[name]}
        onChange={(e) => {
            if(count){
              setInputValue(prev=> {
                return prev.map((pr, index) => {
                  if (index == count-1) {
                    pr.facility = e.target.value
                  }
                  return pr
                })
              })
            }
            else{
              setInputValue((prev)=>({...prev, [name]: e.target.value }));
            }
          }}
          type={type}
          placeholder={placeholder}
          readOnly={readonly? true : false}

        />
      )}
    </div>
  );
};

export default InputField;
