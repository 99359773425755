import { server } from "../fetch";

export const fetchActiveUsers = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/activeUsers`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchPageViews = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/pageViews`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchUserBasedCountry = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/fetchCountry`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchSessionByDevice = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/sessionsByDevice`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchTrafficSource = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/fetchTrafficSource`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();

    if (res2.status == 200 || res2.status == 201) {
      console.log("traffic source", analytics);
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchBounceRate = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/bounceRate`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchSessionPerUser = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/sessionPerUser`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchAverageSessionDuration = async () => {
  try {
    const res2 = await fetch(`${server}/analytics/sessionDuration`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const analytics = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return analytics.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};
