import { message } from "antd";
import { server } from "../fetch";

export const fetchApartments = async () => {
  try {
    const res2 = await fetch(`${server}/apartment`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${acessToken}`,
      },
    });
    const apartments = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return apartments.data;
    }
    
    return [];
  } catch (error) {

    return [];
  }
};

