import React from 'react'
import { useContext } from 'react'
// import { appartmentData } from '../../Assets/Data/Appartments'
import ApartmentPageItem from '../../Components/ApartmentPageItem/ApartmentPageItem'
import { AuthContext } from '../../Context/AuthContext/AuthContext'
import './styles.scss'
const Apartments = () => {
    const {apartments} = useContext(AuthContext)
  return (
    <div className='apartments-page'>
        <div className="container">
            <h2 className="section-heading">Our Apartments</h2>
            <h3 className="section-sub-heading">Browse through our apartments</h3>
            <div className="apartment-page-inner">
                {
                    apartments.map((ap, index)=>{
                        return <ApartmentPageItem item={ap} key={index} />
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default Apartments