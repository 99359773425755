import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import AnalyticsBox from "../../NpComponents/AnalyticsBox/AnalyticsBox";
import Analytics from "../Analytics/Analytics";

import "./styles.scss";
const Dashboard = () => {
  const { apartments, reviews, contact, reservation } = useContext(AuthContext);
  const dashboardAnalyticsData = [
    {
      icon: <i class="fa-solid fa-house"></i>,
      title: "Apartments",
      data: apartments.length,
      background: "blue",
    },
    {
      icon: <i class="fa-solid fa-magnifying-glass"></i>,
      title: "Reviews",
      data: reviews.length,
      background: "blue",
    },
    {
      icon: <i class="fa-solid fa-envelope"></i>,
      title: "Contacts",
      data: contact.length,
      background: "blue",
    },
    {
      icon: <i class="fa-regular fa-address-card"></i>,
      title: "Reservation",
      data: reservation.length,
      background: "red",
    },
  ];

  return (
    <div className="dashboard-page-container">
      <div className="dashboard-analytics-boxes">
        <div className="row">
          {dashboardAnalyticsData.map((data, index) => {
            return (
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <AnalyticsBox item={data} />
              </div>
            );
          })}
        </div>
      </div>
      <Analytics />
    </div>
  );
};

export default Dashboard;
