import { message, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import ImagePopupModal from "../../NpComponents/ImagePopupModal/ImagePopupModal";
import InputField from "../../NpComponents/InputField/InputField";
import { fetchFaqs, fetchReviews } from "../../utils/api-calls/landingCalls";

const AdminFaqEdit = (props) => {
  const { url } = props;
  const { authUser, setFaq } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);

  const [dataValues, setDataValues] = useState({
    title: "",

    detail: ""
  });

  useEffect(() => {
    setDataValues({
      title: props.updateData ? props.updateData.title : "",
      detail: props.updateData ? props.updateData.detail : "",

    });
  }, [props.updateData]);
  const addData = async () => {
    if (!dataValues.title || !dataValues.detail) {
      return message.error("Please insert required values!");
    }
    try {
      const res = await fetch(url, {
        method: props.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          props.updateData
            ? {
              ...dataValues,
              id: props.updateData.id,
            }
            : {
              ...dataValues,
            }
        ),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let faqf = await fetchFaqs();
        setFaq(faqf);
        return message.success(data.message);
      }
      console.log(data.error);
      return message.error(data.message);
    } catch (error) {
      message.error(`${props.updateData ? "Updating" : "Adding"} Failed!`);
    }
  };
  return (
    <div>
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="title"
        type="text"
        placeholder="Enter the title..."
        label="Title"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="detail"
        type="text"
        textArea
        placeholder="Enter the detail..."
        label="Detail"
      />

      <button onClick={() => addData()} className="np-admin-main-button">
        {props.updateData ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default AdminFaqEdit;
