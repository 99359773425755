import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { message } from "antd";
import InputField from "../../NpComponents/InputField/InputField";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import { fetchAbout } from "../../utils/api-calls/aboutCalls";
import ImagePopupModal from "../../NpComponents/ImagePopupModal/ImagePopupModal";
import { fetchLandingHero } from "../../utils/api-calls/landingCalls";
const AdminLandingHero = () => {
  let url = `${server}/landing/landing-hero`;
  const { authUser, landingHero, setLandingHero } = useContext(AuthContext);
  const [dataValues, setDataValues] = useState({
    title: "",
    slogan: "",
    image: "",
  });
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (landingHero) {
      setDataValues({
        title: landingHero.title,
        slogan: landingHero.slogan,
        image: landingHero.image
      });
    }
  }, [landingHero]);

  const updateDatabase = async () => {
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataValues),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let herof = await fetchLandingHero();
        setLandingHero(herof);
        return message.success(data.message);
      }

      return message.error(data.message);
    } catch (error) {
      message.error(`Updating Failed!`);
    }
  };
  return (
    <div className="admin-landing-hero">
      <div className="main-banner">
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="title"
          type="text"
          placeholder="Enter the title..."
          label="Hero Title"
        />

        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="slogan"
          type="text"
          placeholder="Enter the sub title..."
          label="Hero Sub Title"
        />

        <ImagePopupModal
          visible={visible}
          setVisible={setVisible}
          myImage={dataValues.image}
          setMyImage={setDataValues}
          name="image"
          dataValues={dataValues}
          heading="Select Hero Banner"
        />

        <button
          className="np-admin-main-button"
          onClick={() => {
            updateDatabase();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default AdminLandingHero;
