import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { Image, message, Modal } from "antd";
import InputField from "../../NpComponents/InputField/InputField";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import { fetchFooterContact } from "../../utils/api-calls/footerCalls";
const FooterContactDetail = () => {
  let url = `${server}/footerContact`;
  const { authUser, footerContact, setFooterContact } = useContext(AuthContext);
  const [dataValues, setDataValues] = useState({
    email: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    if (footerContact) {
      setDataValues({
        email: footerContact.email,
        phone: footerContact.phone,
        address: footerContact.address,

      });
    }
  }, [footerContact]);

  const updateDatabase = async () => {
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataValues),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let footerContactf = await fetchFooterContact();
        setFooterContact(footerContactf);
        return message.success(data.message);
      }

      return message.error(data.message);
    } catch (error) {
      message.error(`Updating" Failed!`);
    }
  };
  return (
    <div className="admin-landing-hero">
      <div className="main-banner">
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="email"
          type="text"
          placeholder="Enter contact email..."
          label="Email"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="phone"
          type="text"
          placeholder="Enter contact phone..."
          label="Phone"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="address"
          type="text"
          placeholder="Enter contact address..."
          label="Address"
        />

        
        <button
          className="np-admin-main-button"
          onClick={() => {
            updateDatabase();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default FooterContactDetail;
