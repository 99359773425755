import { message, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { LayoutContext } from "../../Context/LayoutContext/LayoutContext";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import InputField from "../../NpComponents/InputField/InputField";
import { fetchContact } from "../../utils/api-calls/contactCalls";
import { fetchReservation } from "../../utils/api-calls/reservation";

const AdminReservationEdit = (props) => {
  const { url } = props;
  const { setTopSheet } = useContext(LayoutContext);

  const { authUser, setReservation } =
    useContext(AuthContext);
  const [dataValues, setDataValues] = useState({
    name: "",
    address: "",
    phone: "",
    viber: "",
    whatsapp: "",

    email: "",
    message: "",
    apartment:"",
    title:""
  });

  useEffect(() => {
    setDataValues({
      name: props.updateData ? props.updateData.name : "",
      address: props.updateData ? props.updateData.address : "",
      phone: props.updateData ? props.updateData.phone : "",
      viber: props.updateData ? props.updateData.viber : "",
      whatsapp: props.updateData ? props.updateData.whatsapp : "",

      email: props.updateData ? props.updateData.email : "",
      message: props.updateData ? props.updateData.message : "",
      apartment: props.updateData ? props.updateData.apartment : "",
      title: props.updateData ? props.updateData.title : "",

    });
  }, [props.updateData]);
  const addData = async () => {
    if (
      !dataValues.name ||
      !dataValues.address ||
      !dataValues.phone ||
      !dataValues.viber ||
      !dataValues.whatsapp ||
      !dataValues.email ||
      !dataValues.message ||
      !dataValues.apartment
    ) {
      return message.error("Please insert required values!");
    }
    try {
      const res = await fetch(url, {
        method: props.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          props.updateData
            ? { ...dataValues, id: props.updateData.id }
            : { ...dataValues }
        ),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let reservationf = await fetchReservation();
        setReservation(reservationf);
        setTopSheet(false);

        return message.success(data.message);
      }
      console.log("error", data)

      return message.error(data.message);
    } catch (error) {
      message.error(`${props.updateData ? "Updating" : "Adding"} Failed!`);
    }
  };

  return (
    <div>
      {/* <div style={{ display: "flex" }}> */}
      <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="title"
          type="text"
          placeholder="Enter apartment..."
          label="Apartemnt Name"
          readonly
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="name"
          type="text"
          placeholder="Enter Name..."
          label="Name"
          readonly
        />

        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="email"
          type="text"
          placeholder="Enter Email..."
          label="Email"
          readonly
        />
      {/* </div> */}
      {/* <div style={{ display: "flex" }}> */}
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="address"
          type="text"
          placeholder="Enter Address..."
          label="User Address"
          readonly
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="phone"
          type="text"
          placeholder="Enter Phone..."
          label="Phone"
          readonly
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="viber"
          type="text"
          placeholder="Enter Viber..."
          label="Viber"
          readonly
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="whatsapp"
          type="text"
          placeholder="Enter Whatsapp..."
          label="Whatsapp"
          readonly
        />
      {/* </div> */}
      {/* <div style={{ display: "flex" }}> */}
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="message"
          type="text"
          placeholder="Enter message..."
          label="Message"
          readonly
          textArea
        />
      {/* </div> */}

      {/* <button onClick={() => addData()} className="np-admin-main-button">
        {props.method == "POST" ? "Add" : "Update"}
      </button> */}
    </div>
  );
};

export default AdminReservationEdit;
