import React from 'react'
import LandingAboutSection from '../../Components/LandingAboutSection/LandingAboutSection';
import LandingAccordion from '../../Components/LandingAccordion/LandingAccordion';
import LandingApartmentsSection from '../../Components/LandingApartmentsSection/LandingApartmentsSection';
import LandingHeroSection from '../../Components/LandingHeroSection/LandingHeroSection';
import LandingReviewsSection from '../../Components/LandingReviewsSection/LandingReviewsSection';
import './styles.scss';
const Home = () => {
  return (
    <div className='home-page'>
        <LandingHeroSection />
        <LandingAboutSection />
        <LandingApartmentsSection />
        <LandingReviewsSection />
        <LandingAccordion />
    </div>
  )
}

export default Home