import React from 'react'
import { Link } from 'react-router-dom'
import Nav from '../../Components/Nav/Nav'
import './styles.scss'
import Logo from '../../Assets/Images/Brand/logo.png'
import { useContext } from 'react'
import { AuthContext } from '../../Context/AuthContext/AuthContext'
const Header = () => {
  const {footerLinks} = useContext(AuthContext)
  return (
    <>
      <div className="header-top">
        <div className="container">
          <div className="header-top-inner">
            <div className="header-top-left"></div>
            <div className="header-top-right">
              <div className="social-media-container">
              <a href={footerLinks && footerLinks.facebook} className="social-icon">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              {/* <a href={footerLinks && footerLinks.twitter} className="social-icon">
                <i class="fa-brands fa-twitter"></i>
              </a> */}
              <a href={footerLinks && footerLinks.instagram} className="social-icon">
                <i class="fa-brands fa-instagram"></i>
              </a>
              {/* <a href="https://youtube.com" className="social-icon">
                <i class="fa-brands fa-youtube"></i>
              </a> */}
              <a href={`https://wa.me/${footerLinks && footerLinks.whatsapp}`} className='social-icon'>
                <i class="fa-brands fa-whatsapp"></i>
                </a>
                <a href={`viber://chat?number=${footerLinks && footerLinks.viber}`} className='social-icon'>
                <i class="fa-brands fa-viber"></i>
                </a>
                <a href={`tel:${footerLinks && footerLinks.phone}`} className='social-icon'>
                <i class="fa-solid fa-phone"></i>                
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='header-container'>
        <div className="container">
          <div className="header-inner">
            <div className="header-logo">
              <Link to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>
            <Nav />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header