import React from 'react'
import Rating from 'react-rating'
import './styles.scss'

const RatingComponent = (props) => {
    const { rating } = props
    return (
        <div>
            <Rating
                start={0}
                stop={5}
                initialRating={rating}
                readonly={true}
                emptySymbol={
                    <span className="star" style={{ color: "grey" }}>
                        <i class="fa-solid fa-star"></i>
                    </span>
                }
                fullSymbol={
                    <span className="star" style={{ color: "gold" }}>
                        <i class="fa-solid fa-star"></i>
                    </span>
                }
            />
        </div>
    )
}

export default RatingComponent