import React from 'react'
import { server } from '../../utils/fetch'
import RatingComponent from '../RatingComponent/RatingComponent'
import './styles.scss'
const ReviewSlideItem = (props) => {
  const { item } = props
  return (
    <div className='reviews-slide-item'>
      <div className="review-top">
        <div className="user-image">
          <img loading="lazy" src={`${server}/${item.image}`} alt="" />
        </div>
        <div className="user-name-top">
          <h3 className="review-name">{item.name}</h3>
          <div className="review-rating">
            <RatingComponent rating={Number(item.rating)} />
          </div>
        </div>

      </div>
      <div className="review-text">
        {item.detail}
      </div>


    </div>
  )
}

export default ReviewSlideItem