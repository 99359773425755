import React from 'react'
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { message } from 'antd';
import './styles.scss'
import Logo from '../../Assets/Images/Brand/logo.png'

import { server } from '../../utils/fetch';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
const Contact = () => {
  const {contactInfo, footerContact, footerLinks} = useContext(AuthContext)
const [name, setName] = useState("")
const [address, setAddress] = useState("")
const [email, setEmail] = useState("")
const [phone, setPhone] = useState("")
const [imessage, setMessage] = useState("")
const [viber, setViber] = useState("")
const [whatsapp, setWhatsapp] = useState("")

  const addData = async (e) => {
    e.preventDefault();
    console.log("form submit", e)
    if (!name || !viber || !whatsapp ||  !phone || !imessage || !address) {
      return message.error("Please insert all the values!");
    }
    try {
      const res = await fetch(`${server}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          address,
          message: imessage,
          viber,
           whatsapp
        }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        setName("");
        setEmail("");
        setMessage("");
        setAddress("");
        setPhone("");
        setViber("");
        setWhatsapp("")
        return message.success(data.message);
      }
      return message.error(data.message);
    } catch (error) {
      message.error(`Sending contact request failed!`);
    }
  };
  return (
    <div className='contact-page'>
      <div className="container">
        <div className="contact-inner">
          <div className="contact-page-left">
            <div className="contact-page-logo">
              <img src={Logo} alt="" />
            </div>
            <h2 className="section-heading">Rupas Home</h2>
            <p className="contact-email">House no {contactInfo && contactInfo.house}</p>
            <p className="contact-email">{contactInfo && contactInfo.address1}
            </p>
            <p className="contact-email">
            {contactInfo && contactInfo.address2}
            </p>
            <h3 className="contact-email-heading">You can directly reach us at</h3>
            <p className="contact-contact-email">{footerContact && footerContact.email}</p>            
            <div className="social-contact-container">
            {/* <p className="contact-else">Contact us through these platforms:</p> */}
            <div className="social-contact-item">
                <span className="social-contact-name">Phone</span>
                <span className="social-contact-val">{contactInfo && contactInfo.phone}</span>
              </div>
              <div className="social-contact-item">
                <span className="social-contact-name">WhatsApp</span>
                <span className="social-contact-val">{contactInfo && contactInfo.whatsapp}</span>
              </div>
              <div className="social-contact-item">
                <span className="social-contact-name">Viber</span>
                <span className="social-contact-val">{contactInfo && contactInfo.viber}</span>
              </div>
            </div>
            <p className="contact-else">Or write us through social medias</p>

            <div className="social-media-container">
            <a href={footerLinks && footerLinks.facebook} className="social-icon">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a href={`https://wa.me/${footerLinks && footerLinks.whatsapp}`} className='social-icon'>
                <i class="fa-brands fa-whatsapp"></i>
                </a>
                <a href={`viber://chat?number=${footerLinks && footerLinks.viber}`} className='social-icon'>
                <i class="fa-brands fa-viber"></i>
                </a>
                <a href={`tel:${footerLinks && footerLinks.phone}`} className='social-icon'>
                <i class="fa-solid fa-phone"></i>                
                </a>
            </div>
          </div>
          <div className="contact-page-right">
            <h2 className="contact-right-heading">Contact Us</h2>
            <Form onSubmit={(e)=> addData(e)}>
              <Form.Group className="mb-2" controlId="formBasicName" >
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Name" required={true}  value={name} onChange={(e)=>setName(e.target.value)}/>
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicAddress" >
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" placeholder="Enter Address"  value={address} onChange={(e)=>setAddress(e.target.value)}/>
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Email address (optional)</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="number" placeholder="Enter Phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                <Form.Text className="text-muted">
                  We'll never share your phone with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicViber">
                <Form.Label>Viber</Form.Label>
                <Form.Control type="number" placeholder="Enter Viber" value={viber} onChange={(e)=>setViber(e.target.value)}/>
                <Form.Text className="text-muted">
                  We'll never share your viber with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-2" controlId="formBasicWhatsApp">
                <Form.Label>Whats App</Form.Label>
                <Form.Control type="number" placeholder="Enter Whatsapp" value={whatsapp} onChange={(e)=>setWhatsapp(e.target.value)}/>
                <Form.Text className="text-muted">
                  We'll never share your whatsapp with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control type="text" placeholder="Enter message"  as="textarea" aria-label="With textarea" value={imessage} onChange={(e)=>setMessage(e.target.value)} />

              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Check me out" />
              </Form.Group> */}
              <button className='main-button' type="submit">
                Submit
              </button>
            </Form>
          </div>
        </div>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3533.1620162607524!2d85.3334443150615!3d27.681386982802774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDQwJzUzLjAiTiA4NcKwMjAnMDguMyJF!5e0!3m2!1sen!2snp!4v1678867843082!5m2!1sen!2snp"
              width="100%" height="440"
              allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Contact