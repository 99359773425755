import { getNodeText } from '@testing-library/react'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './styles.scss'
const Nav = () => {
    const [navOn, setNavOn] = useState(false)
    const [selectedNav, setSelectedNav] = useState('')
    const {pathname} = useLocation()
    const navData = [
        {
            name: "Home",
            link: "/"
        },
        {
            name: "Apartments",
            link: "apartments"
        },
        {
            name: "About Us",
            link: "about-us"
        },
        {
            name: "Contact Us",
            link: "contact-us"
        }
    ]
    useEffect(()=>{
        let p = pathname
        if(pathname.split("/")[1] == ""){
            setSelectedNav("/")
        }
        else{
            setSelectedNav(pathname.split("/")[1])
        }
    },[pathname])
    const onNavData = (item) => {
        setNavOn(false)
        setSelectedNav(item.link)
    }
    console.log(selectedNav)
    return (
        <>
            <div className='nav-container'>
                {navData.map((nd, index) => {
                    return <div className={`nav-list ${selectedNav == nd.link? 'nav-active' :''}`}>
                        <Link to={nd.link} className="nan-is" onClick={() => onNavData(nd)}>{nd.name}</Link>
                    </div>
                })}


            </div>
            <div className="nav-burger" onClick={() => setNavOn(true)}><i class="fa-solid fa-bars"></i></div>
            <div className={`nav-mobile ${navOn ? 'nav-mobile-on' : ""}`}>
                <div className="nav-mobile-cross" onClick={() => setNavOn(false)}><i class="fa-solid fa-xmark"></i></div>
                {navData.map((nd, index) => {
                    return <div className={`nav-list ${selectedNav == nd.link? 'nav-active-mobile' :''}`}>
                    <Link to={nd.link} onClick={() => onNavData(nd)}>{nd.name}</Link>
                    </div>
                })}
            </div>

        </>
    )
}

export default Nav