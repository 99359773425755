import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { Pie } from "@ant-design/plots";
const DeviceChart = () => {
  const { pageViews, trafficSource, sessionsByDevice } =
    useContext(AuthContext);
  const [myDevice, setMyDevice] = useState([]);

  const config = {
    appendPadding: 10,
    data: myDevice,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{name} {percentage}",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  useEffect(() => {
    if (sessionsByDevice && sessionsByDevice.length > 0) {
      const newArr = sessionsByDevice.map((pg, index) => {
        return {
          type: pg.dimensionValues[0].value.toUpperCase(),
          value: parseInt(pg.metricValues[0].value),
        };
      });
      setMyDevice([...newArr]);
    }
  }, [sessionsByDevice, sessionsByDevice.length]);
  return (
    <div>{myDevice && myDevice.length > 0 ? <Pie {...config} /> : null} </div>
  );
};

export default DeviceChart;
