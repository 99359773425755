import { Image, message, Table } from "antd";
import React, { useContext, useState } from "react";
import { LayoutContext } from "../../Context/LayoutContext/LayoutContext";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import AdminApartmentsEdit from "./AdminApartmentsEdit";
import { fetchReviews } from "../../utils/api-calls/landingCalls";

const AdminApartments = () => {
  let url = `${server}/apartment`;
  const { topSheet, setTopSheet, setTopSheetContent } =
    useContext(LayoutContext);
  const { authUser, apartments, setApartments } = useContext(AuthContext);


  const tableItemDelete = async (record) => {
    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: record.id }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let reviewsf = await fetchReviews();
        setApartments(reviewsf);
        return message.success(data.message);
      }
      return message.error(data.message);
    } catch (error) {
      message.error(`Deleting Failed!`);
    }
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      responsive: ["lg"],

    },
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      responsive: ["lg"],

      // render: (text, record) => <Image width={100} src={`${server}/${text}`} />,
    },

    {
      title: "Edit",
      key: "edit",
      render: (text, record) => (
        <button
          className="np-admin-main-button"
          onClick={() => {
            setTopSheetContent(
              <AdminApartmentsEdit
                method="PUT"
                updateData={apartments.find((data) => data.id == record.id)}
                url={url}
              />
            );
            setTopSheet(true);
          }}
        >
          Edit
        </button>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <button
          className="np-admin-main-button"
          onClick={() => tableItemDelete(record)}
        >
          Delete
        </button>
      ),
    },
  ];

  return (
    <div className="admin-store-category">
      <div className="page-heading">
        <button
          className="np-admin-main-button add-button"
          onClick={() => {
            setTopSheetContent(<AdminApartmentsEdit method="POST" url={url} />);
            setTopSheet(true);
          }}
        >
          Add Apartment
        </button>
        <Table dataSource={apartments} columns={columns} />
      </div>
    </div>
  );
};

export default AdminApartments;
