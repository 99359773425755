import { Image, message, Table } from "antd";
import React, { useContext, useState } from "react";
import { LayoutContext } from "../../Context/LayoutContext/LayoutContext";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import AdminDistancesEdit from "./AdminDistancesEdit";
import { fetchFaqs, fetchReviews } from "../../utils/api-calls/landingCalls";

const AdminDistances = () => {
  let url = `${server}/distance`;
  const { topSheet, setTopSheet, setTopSheetContent } =
    useContext(LayoutContext);
  const { authUser, distances, setDistances } = useContext(AuthContext);


  const tableItemDelete = async (record) => {
    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: record.id }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let faqf = await fetchFaqs();
        setDistances(faqf);
        return message.success(data.message);
      }
      return message.error(data.message);
    } catch (error) {
      message.error(`Deleting Failed!`);
    }
  };
  const columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Edit",
      key: "edit",
      render: (text, record) => (
        <button
          className="np-admin-main-button"
          onClick={() => {
            setTopSheetContent(
              <AdminDistancesEdit
                method="PUT"
                updateData={distances.find((data) => data.id == record.id)}
                url={url}
              />
            );
            setTopSheet(true);
          }}
        >
          Edit
        </button>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <button
          className="np-admin-main-button"
          onClick={() => tableItemDelete(record)}
        >
          Delete
        </button>
      ),
    },
  ];

  return (
    <div className="admin-store-category">
      <div className="page-heading">
        <button
          className="np-admin-main-button add-button"
          onClick={() => {
            setTopSheetContent(<AdminDistancesEdit method="POST" url={url} />);
            setTopSheet(true);
          }}
        >
          Add Distance Item
        </button>
        <Table dataSource={distances} columns={columns} />
      </div>
    </div>
  );
};

export default AdminDistances;
