import { Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";

const PagesView = () => {
  const { pageViews } = useContext(AuthContext);
  const [myViews, setMyViews] = useState([]);
  // console.log(pageViews, "pageViews")
  useEffect(() => {
    if (pageViews && pageViews.length > 0) {
      const newArr = pageViews.map((pg, index) => {
        return {
          page: pg.dimensionValues[0].value,
          views: pg.metricValues[0].value,
        };
      });
      setMyViews([...newArr]);
    }
  }, [pageViews, pageViews.length]);
  const columns = [
    {
      title: "Page Name",
      dataIndex: "page",
      key: "page",
      render: (text, record) => (
        <div className="category-table-name">{text}</div>
      ),
    },

    {
      title: "Views",
      dataIndex: "views",
      key: "views",
    },
  ];
  return (
    <div>
      <Table
        dataSource={myViews}
        columns={columns}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default PagesView;
