import React, { useEffect, useState } from "react";
import { useRef } from "react";

export const LayoutContext = React.createContext();

const LayoutProvider = ({ children, ...props }) => {
  const [menuPop, setMenuPop] = useState(false);
  const [topSheetData, setTopSheetData] = useState(null);
  const [cartSlider, setCartSlider] = useState(false);
  const [blogSlider, setBlogSlider] = useState(false);
  const [blogItem, setBlogItem] = useState(null);
  const [cartPopup, setCartPopup] = useState(false);

  const [mobileSlider, setMobileSlider] = useState(false);
  const [mobileCartSlider, setMobileCartSlider] = useState(false);
  const [menuInnerData, setMenuInnerData] = useState([]);
  const [backgroundOverlay, setBackgroundOverlay] = useState(false);
  const [topSheet, setTopSheet] = useState(false);
  const [topSheetContent, setTopSheetContent] = useState(null);

  const changeMouse = (e) => {
    var cursor = document.querySelector(".cursor");
    var circle = document.querySelector(".cursor__inner");
    circle.style.filter = "url(#displacementFilter)";

    cursor.setAttribute("height", "100");
    cursor.setAttribute("width", "100");

    setTimeout(() => {
      cursor.setAttribute("height", "70");
      cursor.setAttribute("width", "70");

      circle.style.filter = "none";
    }, 500);
  };

  const changeMenuInner = (md) => {
    setMenuInnerData((prev) => {
      let pr = prev;
      let newAr = pr.map((p, ind) => {
        if (p.id === md.id) {
          return {
            ...p,
            dishDropdown: !p.dishDropdown,
          };
        }
        return { ...p, dishDropdown: false };
      });
      return [...newAr];
    });
  };
  const clearMenuInner = () => {
    setMenuInnerData((prev) => {
      let pr = prev;
      let newAr = pr.map((p, ind) => {
        if (p.dishDropdown == true) {
          return {
            ...p,
            dishDropdown: false,
          };
        }
        return { ...p, dishDropdown: false };
      });
      return [...newAr];
    });
  };
  const providerValue = {
    blogItem,
    setBlogItem,
    menuPop,
    setMenuPop,
    topSheetData,
    setTopSheetData,
    changeMouse,
    backgroundOverlay,
    setBackgroundOverlay,
    mobileSlider,
    setMobileSlider,
    mobileCartSlider,
    setMobileCartSlider,
    menuInnerData,
    setMenuInnerData,
    changeMenuInner,
    clearMenuInner,
    cartSlider,
    setCartSlider,
    cartPopup,
    setCartPopup,
    blogSlider,
    setBlogSlider,
    topSheet,
    setTopSheet,
    topSheetContent,
    setTopSheetContent,
  };

  return (
    <LayoutContext.Provider value={providerValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
