import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../Context/AuthContext/AuthContext'
import Logo from '../../Assets/Images/Brand/logo.png'

import './styles.scss'
const ContactInfo = () => {
    const {contactInfo, footerLinks, footerContact} = useContext(AuthContext)
    return (
        <div className='contact-info'>
            <div className="contact-page-logo">
              <img src={Logo} alt="" />
            </div>
            <h2 className="section-heading">Rupas Home</h2>
            <p className="contact-email">House no {contactInfo && contactInfo.house}</p>
            <p className="contact-email">{contactInfo && contactInfo.address1}
            </p>
            <p className="contact-email">
            {contactInfo && contactInfo.address2}
            </p>
            <h3 className="contact-email-heading">You can directly reach us at</h3>
            <p className="contact-contact-email">{footerContact && footerContact.email}</p>
            <p className="contact-else">Or write us through social medias</p>
            <div className="social-media-container">
            <a href={footerLinks && footerLinks.facebook} className="social-icon">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              {/* <a href={footerLinks && footerLinks.twitter} className="social-icon">
                <i class="fa-brands fa-twitter"></i>
              </a> */}
              <a href={footerLinks && footerLinks.instagram} className="social-icon">
                <i class="fa-brands fa-instagram"></i>
              </a>
              {/* <a href="https://youtube.com" className="social-icon">
                <i class="fa-brands fa-youtube"></i>
              </a> */}
              <a href={`https://wa.me/${footerLinks && footerLinks.whatsapp}`} className='social-icon'>
                <i class="fa-brands fa-whatsapp"></i>
                </a>
                <a href={`viber://chat?number=${footerLinks && footerLinks.viber}`} className='social-icon'>
                <i class="fa-brands fa-viber"></i>
                </a>
                <a href={`tel:${footerLinks && footerLinks.phone}`} className='social-icon'>
                <i class="fa-solid fa-phone"></i>                
                </a>
            </div>
        </div>
    )
}

export default ContactInfo