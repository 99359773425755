import { server } from "../fetch";

export const fetchFooterAbout = async () => {
  try {
    const res2 = await fetch(`${server}/footerAbout`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const footerAbout = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return footerAbout.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};

export const fetchFooterLinks = async () => {
  try {
    const res2 = await fetch(`${server}/footerLink`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const footerLink = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return footerLink.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};

export const fetchFooterContact = async () => {
  try {
    const res2 = await fetch(`${server}/footerContact`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const footerContact = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return footerContact.data;
    }
    return null;
  } catch (error) {
     
    return null;
  }
};

export const fetchFooterBooking = async () => {
  try {
    const res2 = await fetch(`${server}/footer-booking`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const footerBooking = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return footerBooking.data;
    }
    return null;
  } catch (error) {
     
    return null;
  }
};