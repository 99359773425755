import React from 'react'
import './styles.scss'
import BannerDummy from '../../Assets/Images/Layout/banner-dummy.png'
import { appartmentData } from '../../Assets/Data/Appartments';
import Slider from 'react-slick';
import { bannerData } from '../../Assets/Data/Banner';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
import { server } from '../../utils/fetch';
const LandingHeroSection = () => {
  const { landingHero } = useContext(AuthContext)
  return (
    <section className='landing-hero-section' style={{backgroundImage:`url(${server}/${landingHero && landingHero.image})`}}>
      <div className="container" style={{ position: "relative", height: "100%" }}>
        <div className="landing-hero-text">
          <h2 className="hero-text-heading" data-aos="fade-down">
            {landingHero && landingHero.title}
          </h2>
          <p className="hero-detail" data-aos="fade-up">
          {landingHero && landingHero.slogan}
          </p>

          <Link className="main-button" to="/apartments" >View Apartments</Link>
        </div>
        {/* <Slider className='banner-slider' settings={{
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      }}>
        {bannerData.map((ap, index) => {
          return <img loading="lazy" src={ap.image} alt="" key={index} />
        })}
      </Slider> */}
      </div>

    </section>
  )
}

export default LandingHeroSection