import logo from './logo.svg';
import './App.css';
import "antd/dist/antd.css";


import { useContext, useEffect } from 'react';
import Contact from './Pages/Contact/Contact';
import Apartments from './Pages/Apartments/Apartments';
import ApartmentInner from './Pages/ApartmentInner/ApartmentInner';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import IndexLayout from './Layout/IndexLayout';
import AOS from 'aos';
import ProtectedLayout from './Layout/AdminLayout/IndexLayout';
import Dashboard from './NpPages/Dashboard/Dashboard';
import AdminLogin from './Pages/AdminLogin/AdminLogin';
import AdminLandingHero from './NpPages/AdminLandingHero/AdminLandingHero';
import { AuthContext } from './Context/AuthContext/AuthContext';
import { fetchApartmentInfo, fetchFaqInfo, fetchFaqs, fetchLandingHero, fetchReviewInfo, fetchReviews } from './utils/api-calls/landingCalls';
import { fetchAbout, fetchAmenities, fetchDistances } from './utils/api-calls/aboutCalls';
import AdminAboutInfo from './NpPages/AdminAboutInfo/AdminAboutInfo';
import AdminReviews from './NpPages/AdminReviews/AdminReviews';
import AdminFaq from './NpPages/AdminFaq/AdminFaq';
import AdminDistances from './NpPages/AdminDistances/AdminDistances';
import { fetchContact, fetchContactInfo } from './utils/api-calls/contactCalls';
import AdminContactInfo from './NpPages/AdminContactInfo/AdminContactInfo';
import AdminContact from './NpPages/AdminContact/AdminContact';
import FooterLinksDetail from './NpPages/FooterLinksDetail/FooterLinksDetail';
import FooterContactDetail from './NpPages/FooterContactDetail/FooterContactDetail';
import FooterAboutDetail from './NpPages/FooterAboutDetail/FooterAboutDetail';
import { fetchFooterAbout, fetchFooterBooking, fetchFooterContact, fetchFooterLinks } from './utils/api-calls/footerCalls';
import { fetchReservation } from './utils/api-calls/reservation';
import AdminReservation from './NpPages/AdminReservation/AdminReservation';
import { fetchApartments } from './utils/api-calls/apartmentsCalls';
import AdminApartments from './NpPages/AdminApartments/AdminApartments';
import AdminAmenities from './NpPages/AdminAmenities/AdminAmenities';
import ReactGA from "react-ga4";
import FooterBookingDetail from './NpPages/FooterBookingDetail/FooterBookingDetail';
import AdminReviewInfo from './NpPages/AdminReviewInfo/AdminReviewInfo';
import AdminFaqInfo from './NpPages/AdminFaqInfo/AdminFaqInfo';
import AdminApartmentInfo from './NpPages/AdminApartmentInfo/AdminApartmentInfo';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';


function App() {
  useEffect(() => {
    AOS.init();

    ReactGA.initialize("G-QEBW6XKG5W");

  }, [])

  const {
    setLandingHero,
    authUser,
    setAbout,
    setReviews,
    setFaq,
    setDistances,
    setContactInfo,
    setContact,
    setFooterContact,
    setFooterAbout,
    setFooterLinks,
    setReservation,
    setApartments,
    setAmenities,
    setReviewInfo,
    setFaqInfo,
    setApartmentInfo,
    setFooterBooking

  } = useContext(AuthContext)

  const fetchingLandingHero = async () => {
    const landingHerof = await fetchLandingHero();
    setLandingHero(landingHerof);
  };
  useEffect(() => {
    fetchingLandingHero();
  }, [authUser]);

  const fetchingAboutInfo = async () => {
    const aboutf = await fetchAbout();
    setAbout(aboutf);
  };
  useEffect(() => {
    fetchingAboutInfo();
  }, [authUser]);

  const fetchingReviews = async () => {
    const reviewsf = await fetchReviews();
    setReviews(reviewsf);
  };
  useEffect(() => {
    fetchingReviews();
  }, [authUser]);

  const fetchingFaqs = async () => {
    const faqf = await fetchFaqs();
    setFaq(faqf);
  };
  useEffect(() => {
    fetchingFaqs();
  }, [authUser]);


  const fetchingDistances = async () => {
    const distancef = await fetchDistances();
    setDistances(distancef);
  };
  useEffect(() => {
    fetchingDistances();
  }, [authUser]);

  const fetchingContactInfo = async () => {
    const contactInfof = await fetchContactInfo();
    setContactInfo(contactInfof);
  };
  useEffect(() => {
    fetchingContactInfo();
  }, [authUser]);

  const fetchingContact = async () => {
    const contactF = await fetchContact();
    setContact(contactF);
  };
  useEffect(() => {
    fetchingContact();
  }, [authUser]);


  const fetchingFooterContact = async () => {
    const contactF = await fetchFooterContact();
    setFooterContact(contactF);
  };
  useEffect(() => {
    fetchingFooterContact();
  }, [authUser]);


  const fetchingFooterLinks = async () => {
    const contactF = await fetchFooterLinks();
    setFooterLinks(contactF);
  };
  useEffect(() => {
    fetchingFooterLinks();
  }, [authUser]);


  const fetchingFooterAbout = async () => {
    const footerAboutf = await fetchFooterAbout();
    setFooterAbout(footerAboutf);
  };
  useEffect(() => {
    fetchingFooterAbout();
  }, [authUser]);

  const fetchingReservation = async () => {
    const reservationf = await fetchReservation();
    setReservation(reservationf);
  };
  useEffect(() => {
    fetchingReservation();
  }, [authUser]);

  const fetchingApartments = async () => {
    const apartmentsf = await fetchApartments();
    setApartments(apartmentsf);
  };
  useEffect(() => {
    fetchingApartments();
  }, [authUser]);


  const fetchingAmenities = async () => {
    const amenitiesf = await fetchAmenities();
    setAmenities(amenitiesf);
  };
  useEffect(() => {
    fetchingAmenities();
  }, [authUser]);


  const fetchingApartmentInfo = async () => {
    const apartmentInfof = await fetchApartmentInfo();
    setApartmentInfo(apartmentInfof);
  };
  useEffect(() => {
    fetchingApartmentInfo();
  }, [authUser]);


  const fetchingFaqInfo = async () => {
    const faqInfof = await fetchFaqInfo();
    setFaqInfo(faqInfof);
  };
  useEffect(() => {
    fetchingFaqInfo();
  }, [authUser]);


  const fetchingReviewInfo = async () => {
    const reviewInfof = await fetchReviewInfo();
    setReviewInfo(reviewInfof);
  };
  useEffect(() => {
    fetchingReviewInfo();
  }, [authUser]);


  const fetchingFooterBooking = async () => {
    const footerBookingf = await fetchFooterBooking();
    setFooterBooking(footerBookingf);
  };
  useEffect(() => {
    fetchingFooterBooking();
  }, [authUser]);

  const router = createBrowserRouter([
    {
      path: "*",
      element: 
      <IndexLayout>
        <NotFoundPage />
      </IndexLayout>
    },
    {
      path: "/",
      element: <IndexLayout>
        <Outlet />
      </IndexLayout>,
      children: [
        {
          path: "/",
          element: (
            <Home />
          ),
        },
        {
          path: "about-us",
          element: <About />,
        },
        {
          path: "contact-us",
          element: <Contact />
        },
        {
          path: "apartments",
          element: <Apartments />
        },
        {
          path: "apartments-inner/:apartmentsName",
          element: <ApartmentInner />
        }
      ]
    },

    {
      path: "/admin",
      element: (
        <ProtectedLayout>
          <Outlet />
        </ProtectedLayout>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />
        },
        {
          path: "landing-hero-page",
          element: <AdminLandingHero />
        },
        {
          path: "about-info-page",
          element: <AdminAboutInfo />
        },
        {
          path: "about-distance-page",
          element: <AdminDistances />
        },
        {
          path: "landing-reviews-page",
          element: <AdminReviews />
        },
        {
          path: "landing-faq-page",
          element: <AdminFaq />
        },
        {
          path: "contact-info-page",
          element: <AdminContactInfo />
        },
        {
          path: "contact-page",
          element: <AdminContact />
        },
        {
          path: "reservation-page",
          element: <AdminReservation />
        },
        {
          path: "apartments-page",
          element: <AdminApartments />
        },
        {
          path: "footer-links-page",
          element: <FooterLinksDetail />
        },
        {
          path: "footer-contact-page",
          element: <FooterContactDetail />
        },
        {
          path: "footer-about-page",
          element: <FooterAboutDetail />
        },
        {
          path: "footer-booking-page",
          element: <FooterBookingDetail />
        },
        {
          path: "review-info-page",
          element: <AdminReviewInfo />
        },
        {
          path: "faq-info-page",
          element: <AdminFaqInfo />
        },
        {
          path: "apartment-info-page",
          element: <AdminApartmentInfo />
        },
        {
          path: "about-amenities-page",
          element: <AdminAmenities />
        }
      ]
    },

    {
      path: "/admin",
      element: <ProtectedLayout admin></ProtectedLayout>,
    },
    {
      path: "/admin/admin-login",
      element: <AdminLogin />,
    },

  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />

    </div>
  );
}

export default App;
