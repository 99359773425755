import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { message } from "antd";
import InputField from "../../NpComponents/InputField/InputField";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import { fetchAbout } from "../../utils/api-calls/aboutCalls";
import ImagePopupModal from "../../NpComponents/ImagePopupModal/ImagePopupModal";
import { fetchReviewInfo } from "../../utils/api-calls/landingCalls";
const AdminReviewInfo = () => {
  let url = `${server}/review/review-certificate`;
  const { authUser, reviewInfo, setReviewInfo } = useContext(AuthContext);
  const [dataValues, setDataValues] = useState({
    heading: "",
    subHeading: "",
    image: "",
  });
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (reviewInfo) {
      setDataValues({
        heading: reviewInfo.heading,
        subHeading: reviewInfo.subHeading,
        image: reviewInfo.image
      });
    }
  }, [reviewInfo]);

  const updateDatabase = async () => {
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataValues),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let aboutf = await fetchReviewInfo();
        setReviewInfo(aboutf);
        return message.success(data.message);
      }

      return message.error(data.message);
    } catch (error) {
      message.error(`Updating Failed!`);
    }
  };
  return (
    <div className="admin-landing-hero">
      <div className="main-banner">
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="heading"
          type="text"
          placeholder="Enter the heading..."
          label="Heading"
        />

        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="subHeading"
          type="text"
          placeholder="Enter the sub heading..."
          label="Sub Heading"
        />
        <ImagePopupModal
          visible={visible}
          setVisible={setVisible}
          myImage={dataValues.image}
          setMyImage={setDataValues}
          name="image"
          dataValues={dataValues}
          heading="Select About Image"
        />

        <button
          className="np-admin-main-button"
          onClick={() => {
            updateDatabase();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default AdminReviewInfo;
