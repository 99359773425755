import { server } from "../fetch";

export const fetchHeroTitle = async () => {
  try {
    const res2 = await fetch(`${server}/landing/hero-title`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const banner = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return banner.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};

export const fetchLandingHero = async () => {
  try {
    const res2 = await fetch(`${server}/landing/landing-hero`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const landingHeroData = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return landingHeroData.data;
    }
    return null;
  } catch (error) {
     
    return null;
  }
};


export const fetchReviews = async () => {
  try {
    const res2 = await fetch(`${server}/review`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const review = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return review.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};

export const fetchFaqs = async () => {
  try {
    const res2 = await fetch(`${server}/faq`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const faq = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return faq.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};

export const fetchFaqInfo = async () => {
  try {
    const res2 = await fetch(`${server}/faq-info`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const faq = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return faq.data;
    }
    return null;
  } catch (error) {
     
    return null;
  }
};

export const fetchApartmentInfo = async () => {
  try {
    const res2 = await fetch(`${server}/apartment-info`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const apartmentInfo = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return apartmentInfo.data;
    }
    return null;
  } catch (error) {
     
    return null;
  }
};

export const fetchReviewInfo = async () => {
  try {
    const res2 = await fetch(`${server}/review/review-certificate`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const apartmentInfo = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return apartmentInfo.data;
    }
    return null;
  } catch (error) {
     
    return null;
  }
};