import React from 'react'
import './styles.scss'
// import { appartmentData } from '../../Assets/Data/Appartments';
import ApartmentPageItem from '../ApartmentPageItem/ApartmentPageItem';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
import { server } from '../../utils/fetch';

const LandingApartmentsSection = () => {
  const {apartments, apartmentInfo} = useContext(AuthContext)
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 4,
    // slidesToScroll: 1
  };


  return (
    <section className='landing-apartments-section' style={{backgroundImage:`url(${server}/${apartmentInfo && apartmentInfo.image})`}}>
      <div className="container">
        <h2 className="section-heading">{apartmentInfo && apartmentInfo.heading}</h2>
        <h3 className="section-sub-heading">{apartmentInfo && apartmentInfo.subHeading}</h3>
        <div className="landing-apartments-inner" data-aos="fade-up">
            {apartments.slice(0,3).map((ap, index)=>{
              return <ApartmentPageItem key={index} item={ap} />

            })}

        </div>
        <div className="appartment-button-container">
          <Link className="main-button" to="/apartments">View More</Link>
        </div>
      </div>
    </section>
  )
}

export default LandingApartmentsSection