import { message } from "antd";
import { server } from "../fetch";

export const fetchReservation = async () => {
  try {
    const res2 = await fetch(`${server}/reservation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${acessToken}`,
      },
    });
    const reservation = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return reservation.data;
    }
    
    return [];
  } catch (error) {

    return [];
  }
};

