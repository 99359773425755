import React from "react";
import "./styles.scss";
const AnalyticsBox = (props) => {
  const { item } = props;
  return (
    <div className="analytics-box-container">
      <div
        className="analytics-box-icon"
        // style={{ background: item.background }}
      >
        {item.icon}
      </div>
      <div className="analytics-box-text">
        <div className="analytics-box-title">{item.title}</div>
        <div className="analytics-box-data">{item.data}</div>
      </div>
    </div>
  );
};

export default AnalyticsBox;
