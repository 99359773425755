import { message, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import ImagePopupModal from "../../NpComponents/ImagePopupModal/ImagePopupModal";
import InputField from "../../NpComponents/InputField/InputField";
import { fetchDistances } from "../../utils/api-calls/aboutCalls";
import { fetchFaqs, fetchReviews } from "../../utils/api-calls/landingCalls";

const AdminDistancesEdit = (props) => {
  const { url } = props;
  const { authUser, setDistances } = useContext(AuthContext);

  const [dataValues, setDataValues] = useState({
    location: "",
    distance: ""
  });

  useEffect(() => {
    setDataValues({
      location: props.updateData ? props.updateData.location : "",
      distance: props.updateData ? props.updateData.distance : "",

    });
  }, [props.updateData]);
  const addData = async () => {
    if (!dataValues.location || !dataValues.distance) {
      return message.error("Please insert required values!");
    }
    try {
      const res = await fetch(url, {
        method: props.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          props.updateData
            ? {
              ...dataValues,
              id: props.updateData.id,
            }
            : {
              ...dataValues,
            }
        ),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let distancesf = await fetchDistances();
        setDistances(distancesf);
        return message.success(data.message);
      }
      console.log(data.error);
      return message.error(data.message);
    } catch (error) {
      message.error(`${props.updateData ? "Updating" : "Adding"} Failed!`);
    }
  };
  return (
    <div>
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="location"
        type="text"
        placeholder="Enter the location..."
        label="Location Name"
      />
      <InputField
        inputValue={dataValues}
        setInputValue={setDataValues}
        name="distance"
        type="text"
        placeholder="Enter the distance..."
        label="Distance in KM"
      />

      <button onClick={() => addData()} className="np-admin-main-button">
        {props.updateData ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default AdminDistancesEdit;
