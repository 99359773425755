import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { Image, message, Modal } from "antd";
import InputField from "../../NpComponents/InputField/InputField";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import { fetchFooterLinks } from "../../utils/api-calls/footerCalls";
const FooterLinksDetail = () => {
  let url = `${server}/footerLink`;
  const { footerLinks, setFooterLinks } = useContext(AuthContext);
  const [dataValues, setDataValues] = useState({
    facebook: "",
    insta: "",
    viber: "",
    phone:"",
    whatsapp:""
  });

  useEffect(() => {
    if (footerLinks) {
      setDataValues({
        facebook: footerLinks.facebook,
        viber: footerLinks.viber,
        // linkedIn: footerLinks.linkedIn,
        phone: footerLinks.phone,
        whatsapp: footerLinks.whatsapp,
        insta: footerLinks.insta,
      });
    }
  }, [footerLinks]);

  const updateDatabase = async () => {
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataValues),
      });
      const data = await res.json();

      if (res.status == 200 || res.status == 201) {
        let footerLinksf = await fetchFooterLinks();
        setFooterLinks(footerLinksf);
        return message.success(data.message);
      }

      return message.error(data.message);
    } catch (error) {
      message.error(`Updating" Failed!`);
    }
  };
  return (
    <div className="admin-landing-hero">
      <div className="main-banner">
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="facebook"
          type="text"
          placeholder="Enter facebook link..."
          label="Facebook"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="insta"
          type="text"
          placeholder="Enter insta link..."
          label="Insta"
        />

        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="viber"
          type="text"
          placeholder="Enter viber number..."
          label="Viber"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="whatsapp"
          type="text"
          placeholder="Enter whatsapp number..."
          label="Whatsapp"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="phone"
          type="text"
          placeholder="Enter phone number..."
          label="Phone"
        />

        <button
          className="np-admin-main-button"
          onClick={() => {
            updateDatabase();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default FooterLinksDetail;
