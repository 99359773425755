import React, { useEffect, useState } from "react";
import {
  AppstoreOutlined,
  MinusSquareOutlined,
  MobileOutlined,
  GitlabOutlined,
  BorderlessTableOutlined,
  MailOutlined,
  IdcardOutlined,
  BorderBottomOutlined,
  InstagramOutlined,
  BookOutlined,
  InsertRowBelowOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
const { Header, Sider, Content } = Layout;
const Slider = (props) => {
  const isTablet = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const { collapsed, setCollapsed } = props;
  const { pathname } = useLocation();
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  useEffect(() => {
    if (pathname.split("/")[2] == "") {
      setSelectedMenu("dashboard");
    } else {
      setSelectedMenu(pathname.split("/")[2]);
    }
  }, [pathname]);
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(
      <Link to="/admin">Dashboard</Link>,
      "dashboard",
      <AppstoreOutlined />
    ),
  
    
    getItem("Landing Page", "landing-page", <MinusSquareOutlined />, [
      getItem(
        <Link to="/admin/landing-hero-page">Landing Hero</Link>,
        "landing-hero-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/review-info-page">Reviews Info</Link>,
        "review-info-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/landing-reviews-page">Landing Reviews</Link>,
        "landing-reviews-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/faq-info-page">FAQ Info</Link>,
        "faq-info-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/landing-faq-page">Landing FAQ</Link>,
        "landing-faq-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/apartment-info-page">Apartment Info</Link>,
        "apartment-info-page",
        <AppstoreOutlined />
      ),
    ]),
    getItem("About Page", "about-page", <MobileOutlined />, [
      getItem(
        <Link to="/admin/about-info-page">About Info</Link>,
        "about-info-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/about-distance-page">Distances</Link>,
        "about-distance-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/about-amenities-page">Amenities</Link>,
        "about-amenities-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/contact-info-page">Contact Info</Link>,
        "contact-info-page",
        <AppstoreOutlined />
      ),
    ]),
    
    getItem(
      <Link to="/admin/apartments-page">Apartments Page</Link>,
      "apartments-page",
      <GitlabOutlined />
    ),
    getItem(
      <Link to="/admin/contact-page">Contact Page</Link>,
      "contact-page",
      <MailOutlined />
    ),
    getItem(
      <Link to="/admin/reservation-page">Reservation Page</Link>,
      "reservation-page",
      <IdcardOutlined />
    ),
    getItem("Footer Page", "footer-page", <InsertRowBelowOutlined />, [
      getItem(
        <Link to="/admin/footer-about-page">Footer About</Link>,
        "footer-about-page",
        <AppstoreOutlined />
      ),
      // getItem(
      //   <Link to="/admin/footer-booking-page">Footer Booking</Link>,
      //   "footer-booking-page",
      //   <AppstoreOutlined />
      // ),
      getItem(
        <Link to="/admin/footer-contact-page">Footer Contact</Link>,
        "footer-contact-page",
        <AppstoreOutlined />
      ),
      getItem(
        <Link to="/admin/footer-links-page">Footer Links</Link>,
        "footer-links-page",
        <AppstoreOutlined />
      ),
    ]),
  ];
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={isTablet ? 0 : 100}
      className="sider-container"
    >
      <div className="sider-logo">
        {collapsed ? 'AR' : 'Apartment Rupas'}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        className="sider-menu myScrollbar"
        onClick={(e) => {
          setSelectedMenu(e.key);
          if (isTablet) {
            setCollapsed(true);
          }
        }}
        defaultSelectedKeys={[selectedMenu]}
        selectedKeys={[selectedMenu]}
        items={items}
      />
    </Sider>
  );
};

export default Slider;
