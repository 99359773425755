import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { message } from "antd";
import InputField from "../../NpComponents/InputField/InputField";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import { fetchAbout } from "../../utils/api-calls/aboutCalls";
import { fetchContactInfo } from "../../utils/api-calls/contactCalls";
const AdminContactInfo = () => {
  let url = `${server}/contact/contact-info`;
  const { authUser, contactInfo, setContactInfo } = useContext(AuthContext);
  const [dataValues, setDataValues] = useState({
    house: "",
    address1: "",
    address2: "",
    phone: "",
    viber: "",
    whatsapp: ""
  });

  useEffect(() => {
    if (contactInfo) {
      setDataValues({
        house: contactInfo.house,
        address1: contactInfo.address1,
        address2: contactInfo.address2,
        phone: contactInfo.phone,
        viber: contactInfo.viber,
        whatsapp: contactInfo.whatsapp,

      });
    }
  }, [contactInfo]);

  const updateDatabase = async () => {
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataValues),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        let contactInfoF = await fetchContactInfo();
        setContactInfo(contactInfoF);
        return message.success(data.message);
      }

      return message.error(data.message);
    } catch (error) {
      message.error(`Updating" Failed!`);
    }
  };
  return (
    <div className="admin-landing-hero">
      <div className="main-banner">
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="house"
          type="text"
          placeholder="Enter the house..."
          label="House"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="address1"
          type="text"
          placeholder="Enter address1..."
          label="Address1"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="address2"
          type="text"
          placeholder="Enter address2..."
          label="Address2"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="phone"
          type="text"
          placeholder="Enter phone..."
          label="Phone"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="viber"
          type="text"
          placeholder="Enter viber..."
          label="Viber"
        />
        <InputField
          inputValue={dataValues}
          setInputValue={setDataValues}
          name="whatsapp"
          type="text"
          placeholder="Enter whatsapp..."
          label="Whatsapp"
        />
        <button
          className="np-admin-main-button"
          onClick={() => {
            updateDatabase();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default AdminContactInfo;
