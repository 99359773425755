import React, { useContext } from "react";
import { LayoutContext } from "../../../Context/LayoutContext/LayoutContext";
import RightCol from "../RightCol/RightCol";
import "./styles.scss";
const LayoutBody = (props) => {
  const { topSheet, setTopSheet, topSheetContent } = useContext(LayoutContext);
  return (
    <div className="my-layout-content">
      <div className=" main-body ">
        <div className="main-body-inner myScrollbar">{props.children}</div>
        <div
          className="top-edit-sheet myScrollbar"
          style={{
            transform: topSheet ? "translateX(0%)" : "translateX(-110%)",
          }}
        >
          {topSheet ? (
            <div className="top-edit-inner">{topSheetContent}</div>
          ) : null}
          <div
            className="close-top-sheet"
            onClick={() => {
              setTopSheet(false);
            }}
          >
            <i class="fa-sharp fa-solid fa-xmark"></i>
          </div>
        </div>
      </div>
      <RightCol />
    </div>
  );
};

export default LayoutBody;
