import { server } from "../fetch";

export const fetchAbout = async () => {
  try {
    const res2 = await fetch(`${server}/about/about-info`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const about = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return about.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};


export const fetchDistances = async () => {
  try {
    const res2 = await fetch(`${server}/distance`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const distance = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return distance.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};


export const fetchAmenities = async () => {
  try {
    const res2 = await fetch(`${server}/amenities`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const amenities = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return amenities.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};



export const fetchBrandingText = async () => {
  try {
    const res2 = await fetch(`${server}/brandingText`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const about = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return about.data;
    }
    return [];
  } catch (error) {
     
    return [];
  }
};
