import { message } from "antd";
import React, { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { server } from "../../utils/fetch";
import CryptoJS from "crypto-js";
import "./styles.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { authUser, setAuthUser, setAcessToken, setRefreshToken, secret } =
    useContext(AuthContext);
  useEffect(() => {
    if (authUser) {
      navigate("/admin/dashboard");
    }
  }, [authUser]);
  const onLogin = async () => {
    if (!password || !email) {
      return message.error("Please enter all the values!");
    }
    try {
      const res = await fetch(`${server}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        const encryptUser = CryptoJS.AES.encrypt(
          JSON.stringify(data.user),
          secret
        ).toString();
        const encryptAccess = CryptoJS.AES.encrypt(
          JSON.stringify(data.acessToken),
          secret
        ).toString();
        const Refress = CryptoJS.AES.encrypt(
          JSON.stringify(data.refreshToken),
          secret
        ).toString();
        localStorage.setItem("rupasAuth", encryptUser);
        localStorage.setItem("rupasToken", encryptAccess);
        localStorage.setItem("rupasRefreshToken", Refress);
        if (data.user.role !== "admin") {
          return message.error("You are not authorized to access this page");
        }
        message.success("Login success");

        setPassword("");

        setEmail("");
        setAcessToken(data.acessToken);
        setRefreshToken(data.refreshToken);
        return setAuthUser(data.user);
      }
        return message.error(data.message);
    } catch (error) {
      return message.error("Login failed! please try again");
    }
  };
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#000",
      }}
    >
      <div
        className="container"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#000",
        }}
      >
        <div className="admin-login">
          <form className="joinusForm">
            <div className="login-into">Admin Login</div>
            <div className="row inputSection">
              <div className="col-md-12 col-sm-12 mb-3">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-12 col-sm-12">
                <input
                  type="password"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  style={{ width: "100%" }}
                />
                {/* <div style={{ margin: "10px 0px 10px 0px" }}>
                  <a href="#lk" className="forget-password">
                    Forget password?
                  </a>
                </div> */}
              </div>
            </div>
          </form>
          <div className="buttons">
            <button
              className="alternate-button"
              onClick={(e) => onLogin(e)}
              type="submit"
            >
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
