import React from 'react'
import './styles.scss'
import BannerDummy from '../../Assets/Images/Layout/banner-dummy.png';
import { Link } from 'react-router-dom';
import { server } from '../../utils/fetch';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
import { useContext } from 'react';
const LandingAboutSection = () => {
    const { about } = useContext(AuthContext)

    return (
        <section className='landing-about-section'>
            <div className="container">
                <div className="landing-about-inner">
                    <div className="landing-about-text" data-aos="fade-left">
                        <h2 className="section-heading">{about && about.heading}</h2>
                        <h3 className="section-sub-heading">{about && about.subHeading}</h3>
                        <p className="landing-about-details">
                           {about && about.detail}
                        </p>
                        <Link className="main-button" to="/about-us">Learn More</Link>
                    </div>

                    <div className="landing-about-image" data-aos="fade-right">
                        <img loading="lazy" src={`${server}/${about && about.image}`} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingAboutSection